<template>
  <div class="class-single">
    <div class="subnav-wrapper">
      <div class="container">
        <router-link
          v-if="currentRoute.status"
          :to="{ name: currentRoute.name }"
          class="btn small-cta"
        >
          <img src="../assets/arrow.svg" alt="" /> {{ currentRoute.text }}
        </router-link>
      </div>
    </div>
    <router-view @examSubmitted="updateCurrentRouteStatus"></router-view>
  </div>
</template>

<script>
import CourseService from "@/services/CourseService";

export default {
  name: "SubscribedCourse",
  components: {},
  data() {
    return {
      loading: true,
      currentRoute: {
        name: "",
        text: "",
        status: true,
      },
      params: {
        program_id: this.$route.params.program_id,
        // slug: this.$route.params.slug,
      },
      // Note:- declare program: null, if v-if="program" is going to use
      program: null,
    };
  },
  created() {
    // console.log(this.$route);
    this.loadSubscribedProgram();
    this.switchRoute();
  },
  methods: {
    switchRoute() {
      let routeName = this.$route.name;
      // console.log(routeName);
      if (routeName === "SubscribedCourseActivities") {
        this.currentRoute.name = "MyCourses";
        this.currentRoute.text = "Back to courses";
      }
      if (routeName === "CourseAssignment" || routeName === "CourseExam" || routeName == 'CourseExamInstructions') {
        this.currentRoute.name = "MyCourses";
        this.currentRoute.text = "Back to courses";
        if (routeName === "CourseExam" || routeName == 'CourseExamInstructions') {
          this.currentRoute.status = false;
        }
      }
      return this.currentRoute;
    },
    async loadSubscribedProgram() {
      await CourseService.getSubscribedProgramData(this.params)
        .then((response) => {
          this.loading = false;
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.program = response.data.data.program;
          }
          if (response.data.status === "ERROR") {
            this.$router.push({
              name: "NotFound",
              // preserve current path and remove the first char to avoid the target URL starting with `//`
              params: { pathMatch: this.$route.path.substring(1).split("/") },
              // preserve existing query and hash if any
              // query: this.$route.query,
              // hash: this.$route.hash,
            });
            // this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
          // console.log(this.program);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateCurrentRouteStatus(status) {
      // console.log(status);
      this.currentRoute.status = status;
    },
  },
};
</script>

<style lang="scss">
@import "@/style/class-single.scss";
</style>
